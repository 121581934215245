<template>
  <div>
    <NavBar />
    <Spinner />
    <div id="layoutSidenav_content mb-4">
      <input id="dataBN" type="hidden" />
      <main>
        <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-0 pt-5">
          <div class="container-xl px-4">
            <div class="page-header-content pt-4">
              <div class="row align-items-center justify-content-between">
                <div class="col-12 mt-4 text-center">
                  <h1 class="page-header-title text-center" style="display:block;margin-top:60px">
                    <div class="page-header-icon"></div>Centro de Administraci&oacute;n
                  </h1>
                  <div class="page-header-subtitle">Tio Toto</div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <section class="section-items pt-3 pb-3" style="background-color:#d9d9d9">
          <div class="container pt-3 pb-2">
            <div class="row justify-content-center">
              <div class="col-md-12 text-center">
                <h4 class="title-como-imprimir">
                  <i class="fas fa-check"></i>
                  - PEDIDOS COMPLETADOS ({{pedidosCompletados.length}})
                </h4>
              </div>
            </div>
          </div>
        </section>
        <!-- Main page content-->
        <div class="container-xl mb-4">
          <div class="mb-4" style="margin-botom:50px;">
            <div class="card-body pt-0">
              <div class="row justify-content-center">
                <div class="col-xxl-12 col-xl-12">
                  <div class="row gx-3" >
                        <div class="col-md-5">
                          <input
                            placeholder="Buscar por nro de pedido, nombre o email."
                            name="search"
                            id="buscarIdEmailOrName"
                            class="w-100 mt-5 form-control searchbox-input"
                            v-on:keyup.enter="getPedidosByIdNameOrEmail"
                            />
                        </div>
                        <div class="col-md-2">
                          <a
                            class="width_button btn btn-primary color-btn lift p-2 mt-5"
                            style="border:none;height: 45px;"
                            @click="getPedidosByIdNameOrEmail"
                            >
                              Buscar
                              <i class="ps-2 fas fa-search" style="font-size:22px;"></i>
                            </a>
                        </div>
                        <div class="col-md-2"></div>
                        <div class="col-md-3">
                          <input
                            placeholder="Filtrar"
                            name="search"
                            id="myInput"
                            class="w-100 mt-5 form-control searchbox-input"
                            @keyup="filterPedidos()"
                            />
                        </div>
                  </div>
                  <div class="row gx-3" v-if="pedidosCompletados.length != 0">
                    <div class="col-md-12 mt-4">
                      <table
                        id="myTable"
                        class="table table-hover table-striped table-condensed table-bordered table-mobile"
                      >
                        <thead>
                          <th style="width:6%;">Pedido</th>
                          <th style="width:12%">Fecha Pedido</th>
                          <th style="width:22%">Nombre</th>
                          <th>Email</th>
                          <th style="width:12%">Fecha Impreso</th>
                          <th style="width:6%;">Precio</th>
                          <th style="width:12%;"></th>
                        </thead>
                        <tbody>
                          <tr v-for="(pedido,index) in this.pedidosCompletados" :key="index">
                            <td>{{pedido.id}}</td>
                            <td>{{getDate(pedido.fecha_ingreso)}}</td>
                            <td>{{pedido.nombre}}</td>
                            <td>{{pedido.email}}</td>
                            <td>{{getDate(pedido.fecha_impreso)}}</td>
                            <td>{{formatearMoneda(pedido.precio_total)}}</td>
                            <td>
                              <a
                                class="width_button btn btn-primary color-btn lift p-2 mt-3 me-2 text-center m-2"
                                @click="openModal(pedido.id)"
                                style="border:none;"
                              >
                                <i class="fas fa-info-circle"></i>
                              </a>
                              <a
                                @click="volverPedido(pedido.id)"
                                class="width_button btn btn-primary color-btn lift p-2 mt-3 m-2"
                                style="border:none;"
                              >
                                <i class="fas fa-retweet"></i>
                              </a>
                              <a
                                class="width_button btn btn-primary color-btn lift p-2 mt-3 me-3 m-2"
                                @click="eliminarPedido(pedido.id)"
                                style="border:none;background-color:rgb(181, 70, 70) !important"
                              >
                                <i class="fas fa-trash-alt"></i>
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <!-- <PedidosPendientes :data="pedido" /> -->
                      <hr />
                    </div>
                  </div>
                  <div class="row gx-3 text-center mt-4" v-else>
                    <div class="col-md-12">No hay pedidos completados</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
    <detallePedidoModal v-if="this.modalOpen" :idPedido="this.pedidoID" @onClose="closeModal(pedido.id)"/>
  </div>
</template>

<style>
.titulo-login {
  font-size: 30px;
  color: #24a4d1;
}

.color-btn {
  background: #24a4d1 !important;
  border-color: #24a4d1 !important;
}

.btn-link {
  color: #24a4d1;
  cursor: pointer;
}
.swal-wide {
  width: 1000px !important;
}
</style>

<script>
import NavBar from "../components/navBar";
import router from "@/router/index.js"; // Vue router instance
import Vue from "vue";
import Spinner from "../components/spinner";
import $ from 'jquery'; // <-to import jquery
import PedidosPendientes from "../components/pedidos";
import moment from "moment";
import detallePedidoModal from "../components/detallePedidoModal.vue";
import Footer from "../components/footer";

export default {
  name: "Home",
  components: {
    NavBar,
    Footer,
    PedidosPendientes,
    detallePedidoModal,
    Spinner
  },
  data(){
        return {
            modalOpen:false,
            pedidoID:""
        }
    },
  mounted: function() {
    this.$store.commit("SET_BLOQUEADO", false);
     this.getPedidosCompletados();
  },
  methods: {
    openModal(pedidoID){
            this.modalOpen = true;
            this.pedidoID= pedidoID;
            setTimeout(() => { jQuery("#modalPedidoDetalle").modal("toggle"); }, 100);
    },
    closeModal(idPedido){
            jQuery("#modalPedidoDetalle").modal("toggle");
            setTimeout(() => {
                this.modalOpen = false;
                this.pedidoID= "" ;
            }, 100);
    },
    filterPedidos() {
      const trs = document.querySelectorAll("#myTable tr:not(.header)");
      const filter = document.querySelector("#myInput").value;
      const regex = new RegExp(filter, "i");
      const isFoundInTds = td => regex.test(td.innerHTML);
      const isFound = childrenArr => childrenArr.some(isFoundInTds);
      const setTrStyleDisplay = ({ style, children }) => {
        style.display = isFound([
          ...children // <-- All columns
        ])
          ? ""
          : "none";
      };

      trs.forEach(setTrStyleDisplay);
    },
    volverPedido(idPedido) {
      Vue.swal({
        title: "Tio Toto",
        text:
          "¿Estas segura/o que queres enviar el pedido " +
          idPedido +
          " a pendientes?",
        icon: "info",
        confirmButtonColor: "#3085d6",
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonText: "Si"
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch("pedidos/volverPedido", idPedido);
        }
      });
    },
    eliminarPedido(id) {
      Vue.swal({
        title: "Tío Toto",
        html:
          "¿Estas seguro/a que queres eliminar el pedido N° " +
          id +
          "?. Recordá devolverle el dinero al cliente en el caso de estar pago",
        showCancelButton: true,
        icon: "question",
        confirmButtonColor: "#3085d6",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Confirmar"
      }).then(result => {
        if (result.value) {
          this.$store.dispatch("pedidos/eliminarPedido", id);
        }
      });
    },
    formatearMoneda(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return "$" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    getDate(date) {
      return moment(date, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY HH:mm");
    },
    getActive(active) {
      if (active == this.activo) {
        return "active";
      }
      return "";
    },
    getActiveCard(active) {
      if (active == this.activo) {
        return "active";
      }
      return "";
    },
    getPedidosCompletados() {
      this.clearFilter();
      this.$store.dispatch("pedidos/getPedidosCompletados");
    },
    getPedidosByIdNameOrEmail(){
      const data = $("#buscarIdEmailOrName").val().trim();
      if(data == null || data.length == 0){
        this.getPedidosCompletados();
      }else{
        this.clearFilter();
        this.$store.dispatch("pedidos/getByIdEmaiOrNAme",$("#buscarIdEmailOrName").val())      
      }
    },
    clearFilter(){
      document.querySelector("#myInput").value='';
      this.filterPedidos();
    }
  },
  computed: {
    activo: {
      get() {
        return this.$store.state.user.activo;
      },
      set(value) {
        this.$store.commit("user/SET_ACTIVO", value);
      }
    },
    pedidosCompletados: {
      get() {
        return this.$store.state.pedidos.pedidosCompletados;
      }
    }
  }
};
</script>
