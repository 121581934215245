import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';
import {createStore} from 'vuex-extensions'
import user from '../store/store/user'
import archivos from './store/archivos'
import pedidos from '../store/store/pedidos'
import listaPrecio from '../store/store/listaPrecio'
import preguntaFrecuentes from '../store/store/preguntaFrecuentes'
import auth from './auth'
Vue.use(Vuex)

export default createStore(Vuex.Store, {
    state: {
        bloqueado: false,
    },
    plugins: [createPersistedState()],
    mutations: {
        SET_BLOQUEADO(state, dato) {
            state.bloqueado = dato;
        },
    },
    actions: {
    },
    modules: {
        user,archivos,pedidos,listaPrecio,preguntaFrecuentes,auth
    },
})

