import Vue from 'vue'
import Vuex from 'vuex'
import axiosToken from '../../service/axiosToken'
import router from '../../router';
import store from '../index'; // vuex store instance
Vue.use(Vuex)

export default ({
    namespaced: true,
    state: {
        pedidos:[],
        pedidosCompletados:[],
        pedidoCompletado:"",
        pedidosSeleccionados:[],

    },
    mutations: {
         SET_PEDIDOS(state, dato) {
            state.pedidos = dato;
        },
        SET_PEDIDOS_COMPLETADOS(state, dato) {
            state.pedidosCompletados = dato;
        },
        SET_PEDIDO_COMPLETADO(state, dato) {
            state.pedidoCompletado = dato;
        },
        SET_PEDIDO_SELECCIONADO(state, dato) {
            state.pedidosSeleccionados = dato;
        }
    },
    actions: {
        getPedidosPendientes({ dispatch, commit }) {
             store.commit('SET_BLOQUEADO', true);
                var qs = require('qs');
                var config = {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                      },
                };
                axiosToken.get("api/getPedidosPendientes",config).then(function (response) {
                    store.commit('SET_BLOQUEADO', false);
                    store.commit("pedidos/SET_PEDIDOS",response.data);
                }).catch(function (error) {
                    Vue.swal("Tio Toto",error.response.data,"info");
                    store.commit('SET_BLOQUEADO', false);
                })
        },
        getPedidosCompletados({ dispatch, commit },dataIn) {
            store.commit('SET_BLOQUEADO', true);
               var qs = require('qs');
               var config = {
                   headers: {
                       'Content-Type': 'application/json;charset=UTF-8',
                       'Access-Control-Allow-Origin': '*'
                   },
               };
               axiosToken.get("api/getPedidosCompletadosAll",config).then(function (response) {
                store.commit('SET_BLOQUEADO', false);
                   store.commit("pedidos/SET_PEDIDOS_COMPLETADOS",response.data);
               }).catch(function (error) {
                   Vue.swal("Tio Toto",error.response.data,"info");
                   store.commit('SET_BLOQUEADO', false);
               })
       },
       getByIdEmaiOrNAme({ dispatch, commit },payLoad) {
        store.commit('SET_BLOQUEADO', true);
        let obj = new Object();
        obj.find= payLoad;
        var data = JSON.stringify(obj);
        var config = {
            method: 'post',
            url: '/api/getPedidosByIdEmailOrName',
            data: data,
            headers: {
                'content-type': 'application/json;charset=utf-8',
                'Access-Control-Allow-Origin': '*'
            }
        };
        axiosToken(config).then(function (response) {
            store.commit('SET_BLOQUEADO', false);
            store.commit("pedidos/SET_PEDIDOS_COMPLETADOS",response.data);
        }).catch(function (error) {
            Vue.swal("Tio Toto",error.response.data,"info");
            store.commit('SET_BLOQUEADO', false);
        })
   },
       getPedidoCompletadoByID({ dispatch, commit },id) {
        store.commit('SET_BLOQUEADO', true);
           var qs = require('qs');
           var config = {
               headers: {
                   'Content-Type': 'application/json;charset=UTF-8',
                   'Access-Control-Allow-Origin': '*'
               },
           };
           axiosToken.get("api/getPedidosCompletados/"+id,config).then(function (response) {
               store.commit('SET_BLOQUEADO', false);
               store.commit("pedidos/SET_PEDIDO_COMPLETADO",response.data);
           }).catch(function (error) {
               Vue.swal("Tio Toto",error.response.data,"info");
               store.commit('SET_BLOQUEADO', false);
           })
   },
       updatePedido({ dispatch, commit }, dataIn) {
            store.commit('SET_BLOQUEADO', true);
            var qs = require('qs');
            var data = JSON.stringify(dataIn);
            var config = {
                method: 'post',
                url: '/api/pedidoCompletado',
                data: data,
                headers: {
                    'content-type': 'application/json;charset=utf-8',
                    'Access-Control-Allow-Origin': '*'
                }
            };
            axiosToken(config).then(function (response) {
                store.commit('SET_BLOQUEADO', false);
                Vue.swal({
                    title: "Tio Toto",
                    text: "Se actualizo el pedido N°"+dataIn +" correctamente",
                    icon: "success"
                }).then(function() {
                    window.location = "/home";
                })
            }).catch(function (error) {
                store.commit('SET_BLOQUEADO', false);
                Vue.swal("Tio Toto", JSON.stringify(error.response.data), "error");
            })
        },
        volverPedido({ dispatch, commit }, dataIn) {
            store.commit('SET_BLOQUEADO', true);
            var qs = require('qs');
            var data = JSON.stringify(dataIn);
            var config = {
                method: 'post',
                url: '/api/volverPedido',
                data: data,
                headers: {
                    'content-type': 'application/json;charset=utf-8',
                    'Access-Control-Allow-Origin': '*'
                }
            };
            axiosToken(config).then(function (response) {
                store.commit('SET_BLOQUEADO', false);
                Vue.swal({
                    title: "Tio Toto",
                    text: "Se actualizo el pedido N°"+dataIn +" correctamente",
                    icon: "success"
                }).then(function() {
                    window.location = "/pedidosCompletados";
                })
            }).catch(function (error) {
                store.commit('SET_BLOQUEADO', false);
                Vue.swal("Tio Toto", error.response.data.message, "info");
            })
        },
        sendCotizacionPedido({ dispatch, commit }, dataIn) {
            store.commit('SET_BLOQUEADO', true);
            var qs = require('qs');
            var data = JSON.stringify(dataIn);
            var config = {
                method: 'post',
                url: '/api/enviarPedidoCotizacion',
                data: data,
                headers: {
                    'content-type': 'application/json;charset=utf-8',
                    'Access-Control-Allow-Origin': '*'
                }
            };
            axiosToken(config).then(function (response) {
                store.commit('SET_BLOQUEADO', false);
                Vue.swal({
                    title: "Tio Toto",
                    text: "Se envio un email al usuario con la cotización y el link de mercado pago del pedido N° "+dataIn.id_pedido +" correctamente",
                    icon: "success"
                }).then(function() {
                    window.location = "/home";
                })
            }).catch(function (error) {
                store.commit('SET_BLOQUEADO', false);
                Vue.swal("Tio Toto", JSON.stringify(error.response.data), "error");
            })
        },
        rechazarPedido({ dispatch, commit }, dataIn) {
            store.commit('SET_BLOQUEADO', true);
            var qs = require('qs');
            var data = JSON.stringify(dataIn);
            var config = {
                method: 'post',
                url: '/api/rechazarPedido',
                data: data,
                headers: {
                    'content-type': 'application/json;charset=utf-8',
                    'Access-Control-Allow-Origin': '*'
                }
            };
            axiosToken(config).then(function (response) {
                store.commit('SET_BLOQUEADO', false);
                Vue.swal({
                    title: "Tio Toto",
                    text: "Se rechazo el pedido N° "+dataIn.id_pedido +" correctamente",
                    icon: "success"
                }).then(function() {
                    window.location = "/home";
                })
            }).catch(function (error) {
                store.commit('SET_BLOQUEADO', false);
                Vue.swal("Tio Toto", JSON.stringify(error.response.data), "error");
            })
        },
        eliminarPedido({ dispatch, commit }, dataIn) {
            store.commit('SET_BLOQUEADO', true);
            var qs = require('qs');
            var config = {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                  },
            };
            axiosToken.get("api/eliminarPedido/"+dataIn,config).then(function (response) {
                store.commit('SET_BLOQUEADO', false);
                Vue.swal({
                    title: "Tio Toto",
                    text: "Se elimino el pedido N° "+dataIn+" correctamente. Recorda devolverle el dinero al cliente en el caso de estar pago.",
                    icon: "success"
                }).then(function() {
                    window.location = "/home";
                })
            }).catch(function (error) {
                store.commit('SET_BLOQUEADO', false);
                Vue.swal("Tio Toto", JSON.stringify(error.response.data), "error");
            })
        },
        
    },
    modules: {}
})