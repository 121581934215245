import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import router from '../../router';
import store from '../index'; // vuex store instance
Vue.use(Vuex)

export default ({
    namespaced: true,
    state: {
        user : "",
        name:"",
        celu:"",
        activo:"",
        userID:"",
    },
    mutations: {
        SET_USER(state, dato) {
            state.user = dato;
        },
        SET_USERID(state, dato) {
            state.userID = dato;
        },
        SET_NAME(state, dato) {
            state.name = dato;
        },
        SET_CELU(state, dato) {
            state.celu = dato;
        },
        SET_ACTIVO(state, dato) {
            state.activo = dato;
        },
    },
    actions: {
        login({ dispatch, commit }, dataObj) {
            store.commit('SET_BLOQUEADO', true);
            var data = JSON.stringify(dataObj);
            var config = {
                method: 'post',
                url: '/api/login',
                data: data,
                headers: {
                    'content-type': 'application/json;charset=utf-8'
                }
            };
            axios(config).then(function (response) {
                store.commit('SET_BLOQUEADO', false);
                store.commit("user/SET_USER", dataObj.user);
                store.commit("auth/SET_TOKEN", response.data["jwt-token"]);
                router.push("/home");
            }).catch(function (error) {
                store.commit('SET_BLOQUEADO', false);
                Vue.swal("Tio Toto", error.response.data, "info");
            })
        },
    },
    modules: {}
})