<template>
<div>
    <NavBar />
    <Spinner />
    <div id="layoutSidenav_content mb-4">
        <main>
            <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-0 pt-5">
                <div class="container-xl px-4">
                    <div class="page-header-content pt-4">
                        <div class="row align-items-center justify-content-between">
                            <div class="col-12 mt-4 text-center">
                                <h1 class="page-header-title text-center" style="display:block;margin-top:60px">
                                    <div class="page-header-icon"></div>
                                    Centro de Administraci&oacute;n
                                </h1>
                                <div class="page-header-subtitle">Tio Toto</div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <section class="section-items pt-3 pb-3" style="background-color:#d9d9d9">
				<div class="container pt-3 pb-2">
					<div class="row justify-content-center">
						<div class="col-md-12 text-center">
							<h4 class="title-como-imprimir"><i class="fas fa-dollar-sign"></i> -  Lista de Precios</h4>
						</div>
					</div>
				</div>
			</section>
            <!-- Main page content-->
            <div class="container-xl mb-4">
                <div class="mb-4" style="margin-botom:50px;">
                    <div class="card-body pt-0">
                        <div class="row justify-content-center">
                            <div class="col-xxl-9 col-xl-8">
                                <div class="row gx-3">
                                    <div class="col-md-12">
                                        <table class="table table-hover table-striped table-bordered table-mobile mt-4">
                                            <thead>
                                                <th class="thead">Tamaño</th>
                                                <th class="thead">Faz</th>
                                                <th class="thead">Calidad</th>
                                                <th class="thead">Tipo Hoja</th>
                                                <th class="thead" style="width:10%">Precio</th>
                                                <th ></th>                   
                                            </thead>
                                            <tbody>
                                                <tr v-for="(precio,index) in listaPrecio.listPrice" :key="index">
                                                    <td class="td-precio" data-label="Tamaño">{{precio.tam_hoja}} - {{precio.desc_tam_hoja}}</td>
                                                    <td class="td-precio" data-label="Faz">{{precio.faz}}</td>
                                                    <td class="td-precio" data-label="Calidad">{{precio.calidad}}</td>
                                                    <td class="td-precio" data-label="Tipo Hoja">{{precio.tipo_hoja}} - {{precio.desc_tipo_hoja}}</td>
                                                    <td class="td-precio" data-label="Precio"><input :id="'precio_nuevo_'+precio.id" :value="precio.precio" class="form-control"/></td>
                                                    <td class="text-center"><a @click="updatePrecio(precio)" class="btn btn-primary color-btn lift p-2 me-4 mt-3" style="background-color:#4c994c !important;border:none;margin-top: 4px !important;">Actualizar <i class="ps-2 fas fa-thumbs-up" style="font-size:23px;"></i></a></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="col-md-12">
                                        <table class="table table-hover table-bordered table-mobile mt-4">
                                            <thead>
                                                <th class="thead" style="width:72%;">Tipo</th>
                                                <th class="thead" style="width:10%">Precio</th>
                                                <th ></th>                   
                                            </thead>
                                            <tbody>
                                                <tr v-for="(precio,index) in listaTerminado" :key="index">
                                                    <td class="td-precio" data-label="Tamaño">{{precio.nombre}} - {{precio.descripcion}}</td>
                                                    <td class="td-precio" data-label="Precio"><input :id="'precio_nuevo_terminado_'+precio.id" :value="precio.precio" class="form-control"/></td>
                                                    <td class="text-center"><a @click="updateTerminado(precio)" class="btn btn-primary color-btn lift p-2 me-4 mt-3" style="background-color:#4c994c !important;border:none;margin-top: 4px !important;">Actualizar <i class="ps-2 fas fa-thumbs-up" style="font-size:23px;"></i></a></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</div>
</template>

<style>
.titulo-login {
    font-size: 30px;
    color: #24a4d1;
}

.td-precio{
    font-size: 14px;
}

.thead{
    font-size: 18px;
    padding: 5px;
}

.color-btn {
    background: #24a4d1 !important;
    border-color: #24a4d1 !important;
}

.btn-link {
    color: #24a4d1;
    cursor: pointer;
}
</style>

<script>
import NavBar from "../components/navBar"
import router from '@/router/index.js' // Vue router instance
import Vue from 'vue'
import Spinner from '../components/spinner'
import CardButton from '../components/home/cardButton'
import $ from 'jquery'

import Footer from '../components/footer'
export default {
    name: "Home",
    components: {
        NavBar,
        Footer,
        CardButton,
        Spinner
    },
    mounted: function () {
        this.getListaPrecio();
        this.getListaTerminado();
        this.$store.commit("SET_BLOQUEADO", false);

    },
    methods: {
        getListaPrecio() {
            this.$store.dispatch("listaPrecio/getListaPrecio");
        },
        getListaTerminado() {
            this.$store.dispatch("listaPrecio/getListaTerminado");
        },
        updatePrecio(precio) {
            Vue.swal({
                title: 'Tio Toto',
                text: "¿Estas segura/o que queres modificar el precio de "+precio.tam_hoja+ " - "+precio.desc_tam_hoja +" ?",
                icon: 'info',
                confirmButtonColor: '#3085d6',
                showCancelButton: true,
                cancelButtonText: 'No',
                confirmButtonText: 'Si'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch("listaPrecio/updatePrecio", this.getObjPrecio(precio));
                }
            })
        },
        updateTerminado(precio){
            Vue.swal({
                title: 'Tio Toto',
                text: "¿Estas segura/o que queres modificar el precio de "+precio.nombre+"?",
                icon: 'info',
                confirmButtonColor: '#3085d6',
                showCancelButton: true,
                cancelButtonText: 'No',
                confirmButtonText: 'Si'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch("listaPrecio/updatePrecioTerminado", this.getObjPrecioTerminado(precio));
                }
            })
        },
        getObjPrecio(precio){
            let obj = new Object();
            obj.codigo=precio.codigo_precio;
            obj.precio = $("#precio_nuevo_"+precio.id).val();
            return obj;
        },
        getObjPrecioTerminado(precio){
            let obj = new Object();
            obj.codigo=precio.codigo;
            obj.precio = $("#precio_nuevo_terminado_"+precio.id).val();
            return obj;
        }
    },
    computed: {
        listaPrecio: {
            get() {
                return this.$store.state.listaPrecio.listaPrecio;
            }
        },
        listaTerminado: {
            get() {
                return this.$store.state.listaPrecio.listaTerminado;
            }
        }
    },
};
</script>
