import axios from 'axios';
import store from '../store/index'; // vuex store instance
import router from '../router';
import Vue from 'vue'

const axiosInstance = axios.create({});

//interceptor request
axiosInstance.interceptors.request.use((config)=>{
    let state = store;
    let params = new URLSearchParams();
    
    params.append('token',state.state.auth.token);
    params.append('user',state.state.user.user);
    config.params=params;
    config.headers.token=state.state.auth.token;
    return config;
  })

  //interceptor responsee
  axiosInstance.interceptors.response.use((response) => {
    if(response.headers.nuevo_access_token != null && response.headers.nuevo_access_token.length>1){
      let state = store;
      state.commit('auth/SET_TOKEN',response.headers.nuevo_access_token);
    }
    return response;
    }, (error) => {
          try {
            let state = store;
            state.commit("SET_BLOQUEADO", false);
            if(error.response.status == '401'){
                Vue.swal({
                    title: "Tio Toto",
                    text: "Token inválido",
                    icon: "error"
                }).then(function() {
                    window.location = "/login";
                })
            }
          } catch (error) {
            let state = store;
            state.commit("SET_BLOQUEADO", false);
            Vue.swal({
                title: "Tio Toto",
                text: "Token inválido",
                icon: "error"
            }).then(function() {
                window.location = "/login";
            })      }
      return Promise.reject(error);
    });




  export default axiosInstance;