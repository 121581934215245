<template>
<div v-bind:class="{ ocultar: !this.$store.state.bloqueado }" style="position: fixed; z-index: 9999; top: 0px; left: 0px; width: 100%; height: 100%; background: grey; opacity: 0.5;">
    <!-- <div class="lds-dual-ring">        <i class="fas fa-print"></i> </div> -->
    <button class="spinner-btn">
        <span class="spinner"></span>
        <!-- <i class="fas fa-print fa-spin"></i> -->
        <span class="btn__text">Cargando...</span>
    </button>
</div>
</template>

<style>
.lds-dual-ring {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 100px;
    height: 100px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #033349;
    border-color: #033349 transparent #033349 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

.spinner-btn {
    font-size: 1.3rem;
    border: none;
    background-color: #1d3557;
    color: #f1faee;
    padding: 0.8rem 1.5rem;
    border-radius: 2rem;
    transition: 0.3s ease;
    box-shadow: 0 1rem 2rem -1rem rgba(0, 0, 0, 0.445);
    position: absolute;
    top: 50%;
    left: 45%;
    margin-left: -50px;
    margin-top: -50px;
    width: 200px;
    height: 100px;
}

.btn__text {
    margin-left: 0.8rem;
}

.spinner {
    width: 1.5rem;
    height: 1.5rem;
    display: inline-block;
    border: 3px solid #dddf00;
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-radius: 50%;
    animation: spin 1s infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0);
    }

    50% {
        transform: rotate(180deg);
        border-top-color: #fcab41;
        border-bottom-color: #80ffdb;
        border-right-color: transparent;
        border-left-color: transparent;
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.ocultar {
    display: none !important;
}
</style>

<script>
export default {
    name: "spinner",
};
</script>
