import Vue from 'vue'
import Vuex from 'vuex'
import axiosToken from '../../service/axiosToken'
import router from '../../router';
import store from '../index'; // vuex store instance
Vue.use(Vuex)

export default ({
    namespaced: true,
    state: {
        preguntaFrecuentes:[],
    },
    mutations: {
         SET_PREGUNTA_FRECUENTES(state, dato) {
            state.preguntaFrecuentes = dato;
        },
    },
    actions: {
        get({ dispatch, commit }) {
             store.commit('SET_BLOQUEADO', true);
                var qs = require('qs');
                var config = {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        'Access-Control-Allow-Origin': '*'
                    },
                };
                axiosToken.get("api/getPreguntasFrecuentes",config).then(function (response) {
                    store.commit('SET_BLOQUEADO', false);
                    store.commit("preguntaFrecuentes/SET_PREGUNTA_FRECUENTES",response.data);
                }).catch(function (error) {
                    Vue.swal("Tio Toto",error.response.data,"info");
                    store.commit('SET_BLOQUEADO', false);
                })
        },
        deletePregunta({ dispatch, commit }, id) {
            store.commit('SET_BLOQUEADO', true);
            var qs = require('qs');
            var config = {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Access-Control-Allow-Origin': '*'
                },
            };
            axiosToken.get("api/deletePregunta/"+id,config).then(function (response) {
                store.commit('SET_BLOQUEADO', false);
                Vue.swal({
                    title: "Tio Toto",
                    text: "Se elimino la pregunta N°"+id +" correctamente",
                    icon: "success"
                }).then(function() {
                    window.location = "/preguntasFrecuentes";
                })
            }).catch(function (error) {
                Vue.swal("Tio Toto",error.response.data,"info");
                store.commit('SET_BLOQUEADO', false);
            })
        },
        crearPregunta({ dispatch, commit }, obj) {
            store.commit('SET_BLOQUEADO', true);
            var qs = require('qs');
            var data = JSON.stringify(obj);
            var config = {
                method: 'post',
                url: '/api/crearPregunta',
                data: data,
                headers: {
                    'content-type': 'application/json;charset=utf-8',
                }
            };
            axiosToken(config).then(function (response) {
                store.commit('SET_BLOQUEADO', false);
                Vue.swal({
                    title: "Tio Toto",
                    text: "Se creo la pregunta correctamente",
                    icon: "success"
                }).then(function() {
                    window.location = "/preguntasFrecuentes";
                })
            }).catch(function (error) {
                store.commit('SET_BLOQUEADO', false);
                Vue.swal("Tio Toto", error.response.data.message, "info");
            })
        },

        
    },
    modules: {}
})