import Vue from 'vue'
import Vuex from 'vuex'
import axiosToken from '../../service/axiosToken'
import router from '../../router';
import store from '../index'; // vuex store instance
Vue.use(Vuex)

export default ({
    namespaced: true,
    state: {
    },
    mutations: {
         //file 
    },
    actions: {
      getArchivosByCotizacion({ dispatch, commit }, obj) {
            store.commit('SET_BLOQUEADO', true);
            return new Promise((resolve, reject) => {
            var config = {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    "Access-Control-Allow-Origin": "*"
                },
              };
              axiosToken.get("/api/getArchivosByCotizacion/"+obj,config).then(function (response) {
              store.commit('SET_BLOQUEADO', false);
              resolve(response.data)
            }).catch(function (error) {
                store.commit('SET_BLOQUEADO', false);
                reject(error)
            })
          })
        },
    },
    modules: {}
})