<template>
<div>
    <NavBar />
    <Spinner />
    <div id="layoutAuthentication">
        <div id="layoutAuthentication_content">
            <div class="container-xl px-4" style="margin-top:90px;margin-bottom:0px;">
                <div class="row justify-content-center">
                    <div class="col-xl-5 col-lg-6 col-md-8 col-sm-11">
                        <div class="card my-5">
                            <div class="card-body p-5 text-center">
                                <div class="h3 fw-light mb-3 titulo-login">Loguearse</div>
                            </div>
                            <hr class="my-0" />
                            <div class="card-body p-5">
                                <input type="hidden" id="tokenReCaptcha" />
                                <form>
                                    <div class="mb-3 text-left">
                                        <label class="text-gray-600 small " for="emailExample">Usuario</label>
                                        <input class="form-control form-control-solid" type="text" placeholder="" id="nameUser" aria-label="Usuario" />
                                    </div>
                                    <div class="mb-3 text-left">
                                        <label class="text-gray-600 small " for="emailExample">Contraseña</label>
                                        <input class="form-control form-control-solid" type="password" placeholder="" id="passwordUser" aria-label="pass" />
                                    </div>
                                    <!-- <div class="g-recaptcha" data-sitekey="6LfZ_XQfAAAAACRm_ELPcitLTDE4Q5yFSRkeNaEI" data-callback="recaptcha_callback"></div> -->
                                    <div class="d-flex align-items-center justify-content-center mb-0 mt-5">
                                        <a class="btn btn-primary color-btn lift" @click="redirectHome">Ingresar</a>
                                    </div>
                                </form>
                            </div>
                            <hr class="my-0" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<style>
.titulo-login {
    font-size: 30px;
    color: #24a4d1;
}

.color-btn {
    background: #24a4d1 !important;
    border-color: #24a4d1 !important;
}

.btn-link {
    color: #24a4d1;
    cursor: pointer;
}
</style>

<script>
import NavBar from "../components/navBar"
import router from '@/router/index.js' // Vue router instance
import Vue from 'vue'
import $ from 'jquery'
import Spinner from '../components/spinner'
import Footer from '../components/footer'

//   var onloadCallback = function () {
//             grecaptcha.render('html_element', {
//                 'sitekey': '6LfZ_XQfAAAAACRm_ELPcitLTDE4Q5yFSRkeNaEI'
//             });
//         }

export default {
    name: "Home",
    components: {
        NavBar,
        Footer,
        Spinner
    },
    mounted: function () {
        this.$store.commit("user/SET_USER", "");
        this.$store.commit("user/SET_PASS", "");
        this.$store.commit("auth/SET_TOKEN", "");
        this.$store.commit("SET_BLOQUEADO", false);

      
    },
    methods: {
        backLanding: function () {
            router.push("/");
        },
        redirectHome() {
            try {
                // $("#tokenReCaptcha").val(this.validarCatpcha());
                this.$store.dispatch("user/login", this.getData())
            } catch (error) {
                Vue.swal("Impresiones Tío Toto", error, "info");
            }
        },
        getData() {
            let obj = new Object();
            obj.user = $("#nameUser").val().toLowerCase();
            obj.pass = $("#passwordUser").val();
            // obj.tokenGoogle = $("#tokenReCaptcha").val();
            return obj;
        },
        validarCatpcha() {
            //se hace esto para obtener el token del reCatpcha
            var widgetId1;
            var onloadCallback = function () {
                // The id of the reCAPTCHA widget is assigned to 'widgetId1'.
                widgetId1 = grecaptcha.render('tokenReCaptcha', {
                    'sitekey': '6LfZ_XQfAAAAACRm_ELPcitLTDE4Q5yFSRkeNaEI',
                    'theme': 'light'
                });
            }
            return grecaptcha.getResponse(widgetId1);
        },
    },
    computed: {},
};
</script>
