<template>
<div>
    <NavBar />
    <Spinner />
    <div id="layoutSidenav_content ">
        <main class="mb-4">
            <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10 pt-5">
                <div class="container-xl px-4">
                    <div class="page-header-content pt-4">
                        <div class="row align-items-center justify-content-between">
                            <div class="col-12 mt-4 text-center">
                                <h1 class="page-header-title text-center" style="display:block;margin-top:60px">
                                    <div class="page-header-icon"></div>
                                    Preguntas Frecuentes
                                </h1>
                                <div class="page-header-subtitle">Tio Toto</div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
             <section class="section-items pt-3 pb-3" style="background-color:#d9d9d9">
				<div class="container pt-3 pb-2">
					<div class="row justify-content-center">
						<div class="col-md-12 text-center">
							<h4 class="title-como-imprimir"><i class="fas fa-question"></i> -   Preguntas Frecuentes</h4>
						</div>
					</div>
				</div>
			</section>
            <!-- Main page content-->
            <div class="container-xl mb-4">
                <div class="mb-4" style="margin-botom:50px;">
                    <div class="card-body pt-0">
                        <div class="row justify-content-center">
                            <div class="col-xxl-9 col-xl-8">
                                <div class="row gx-3">
                                    <div class="col-md-12">
                                        <div class="card mt-4 card-filter mb-4">
                                            <div class="card-body body-filter p-3">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        Nueva pregunta
                                                    </div>
                                                    <div class="col-md-6 pt-4">
                                                        <input class="form-control" placeholder="Pregunta.." id="pregunta" style="height: 40px;" />
                                                    </div>
                                                    <div class="col-md-12 pt-4">
                                                        <textarea rows="4" placeholder="Cuerpo.." id="cuerpo" class="form-control" />
                                                        </div>
                                                    <div class="col-md-12 text-center mt-4">
                                                        <a @click="nuevaPregunta()" class="btn btn-primary color-btn lift p-2 me-4 mt-3" style="background-color: rgb(36 164 209) !important;  border: none;  width: 15%;  margin-top: 4px !important;  font-size: 17px;">Agregar </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <table class="table table-striped table-bordered table-mobile mt-4">
                                            <thead>
                                                <th class="thead" style="width:10%">ID</th>
                                                <th class="thead" style="width:25%">Pregunta</th>
                                                <th class="thead" style="width:40%">Cuerpo</th>
                                                <th style="width:10%"></th>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(pregunta,index) in preguntasFrecuentes" :key="index">
                                                    <td class="td-preg">{{pregunta.id}}</td>
                                                    <td class="td-preg">{{pregunta.pregunta}}</td>
                                                    <td class="td-preg">{{pregunta.cuerpo}}</td>
                                                    <td class="text-center td-preg"><a @click="deletePregunta(pregunta.id)" class="btn btn-primary color-btn lift p-2 me-4 mt-3" style="background-color:#f19538 !important;border:none;margin-top: 4px !important;">Eliminar <i class="ps-2 fas fa-trash-alt" style="font-size:23px;"></i></a></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</div>
</template>

<style>
.titulo-login {
    font-size: 30px;
    color: #24a4d1;
}

.td-preg.{
    font-size: 14px;
}

.thead {
    font-size: 18px;
    padding: 5px;
}

.color-btn {
    background: #24a4d1 !important;
    border-color: #24a4d1 !important;
}

.btn-link {
    color: #24a4d1;
    cursor: pointer;
}
</style>

<script>
import NavBar from "../components/navBar"
import router from '@/router/index.js' // Vue router instance
import Vue from 'vue'
import Spinner from '../components/spinner'
import $ from 'jquery'

import Footer from '../components/footer'
export default {
    name: "Home",
    components: {
        NavBar,
        Footer,
        Spinner
    },
    mounted: function () {
        this.getPreguntasFrecuente();
        this.$store.commit("SET_BLOQUEADO", false);
    },
    methods: {
        getPreguntasFrecuente() {
            
            this.$store.dispatch("preguntaFrecuentes/get");
        },
        deletePregunta(id) {
            this.$store.dispatch("preguntaFrecuentes/deletePregunta", id);
        },
        nuevaPregunta() {
            try {
                let pregunta = $("#pregunta").val();
                let cuerpo = $("#cuerpo").val();
                if(pregunta == ""){
                    throw("la pregunta no puede estar vacia");    
                }
                if(cuerpo == ""){
                    throw("el cuerpo no puede estar vacio");    
                }
                this.$store.dispatch("preguntaFrecuentes/crearPregunta", this.getDataObj());
            } catch (error) {
                Vue.swal("Tio Toto",error,"info");
            }
        },
        getDataObj(){
            let pregunta = $("#pregunta").val();
            let cuerpo = $("#cuerpo").val();
            let obj = new Object();
            obj.pregunta = pregunta;
            obj.cuerpo = cuerpo;
            return obj;
        }

    },
    computed: {
        preguntasFrecuentes: {
            get() {
                return this.$store.state.preguntaFrecuentes.preguntaFrecuentes;
            }
        }
    },
};
</script>
