import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default ({
    namespaced: true,
    state: {
        token: "",
    },
    mutations: {
        SET_TOKEN(state, dato) {
            state.token = dato;
        },
    },
    actions: {

    },
    modules: {},
})