<template>
<div>
    <div class="footer">
        <div class="container px-4">
            <div class="row mt-4">
                <div class="col-md-4">
                    <div class="row">
                        <div class="col-md-12 font-footer-icon mb-3"><i class="fas fa-home"></i> <i class="fas fa-clock"></i></div>
                        <div class="col-md-12 font-footer">Uriburu 756 (1027) Localidad Balvanera Buenos Aires, Argentina <br> Horario de atención lunes a viernes de 10 a 18hs</div>
                    </div>
                </div>
                 <div class="col-md-4">
                    <div class="row">
                        <div class="col-md-12 font-footer-icon mb-3"><i class="fas fa-phone"></i> <i class="fab fa-whatsapp"></i></div>
                        <div class="col-md-12 font-footer">011 6352-0103 <br> WhatsApp para consultas +54 11 6352-0103</div>
                    </div>
                </div>
                 <div class="col-md-4">
                    <div class="row">
                        <div class="col-md-12 font-footer-icon mb-3"><i class="fas fa-envelope"></i></div>
                        <div class="col-md-12 font-footer">Email: pedidosonlinetiototo@gmail.com</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<style>
.footer {
   border-top: 1px solid black ;
   padding-bottom: 70px;
   padding-top: 30px;
}
.font-footer{
    font-size:15px;
}
.font-footer-icon{
    font-size: 20px;
}
</style>

<script>
import router from '@/router/index.js' // Vue router instance
export default {
    components: {},
    mounted: function () {},
    methods: {},
    computed: {
        //mapea store con la vista
    },
    props: {
    }
}
</script>>
