<template>
<div>
    <nav class="topnav navbar shadow  navbar-expand-lg navbar-light bg-light justify-content-between justify-content-sm-start navbar-light bg-color-custom">
        <div class="container">
            <a class="navbar-brand pe-3 ps-4 ps-lg-2" href="#">
                <img class="img-navbar" style="height:90px !important" src="assets/img/logoTioToto_remove.png" @click="redirectLanding">
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse d-md-flex d-lg-flex" id="navbarNavAltMarkup" style="background: #f8f9fa!important;">
                <div class="navbar-nav" v-if="jwtToken.length>1">
                    <a class="nav-link btn-navbar" style="cursor:pointer;" @click="redirect('home')">Pedidos Pendientes</a>
                    <a class="nav-link btn-navbar" style="cursor:pointer;" @click="redirectCompletados()">Pedidos Completados</a>
                    <a class="nav-link btn-navbar" style="cursor:pointer;" @click="redirect('listaPrecios')">Lista de Precios</a>
                    <a class="nav-link btn-navbar" style="cursor:pointer;" @click="redirect('preguntasFrecuentes')">Preguntas Frecuentes</a>
                    <a class="nav-link btn-navbar" style="cursor:pointer;" @click="redirectLogin">Salir</a>
                </div>
            </div>
        </div>
    </nav>
</div>
</template>

<style>
.img-navbar {
    cursor: pointer;
    height: 65px !important;
    margin: 1px;
    padding: 0;
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 0;
    padding-top: 0;
    margin-top: 10px;
    margin-bottom: 10px;
}

.btn-navbar {
    color: white !important;
}

.bg-color-custom {
    background: #f8f9fa!important;
}

.user-profile {
    font-size: 35px;
    color: white;
}

.cursor-pointer {
    cursor: pointer;
}
</style>

<script>
import router from '@/router/index.js' // Vue router instance
export default {
    components: {},
    mounted: function () {
        //ejectuar cuando inicia
    },
    methods: {
        redirectLogin() {
            window.location.href = "/login"
        },
        redirectLanding() {
            router.push("/home");
        },
        redirect(path) {
            router.push("/" + path)
        },
        redirectCompletados() {
            router.push("/pedidosCompletados");
        }
    },
    computed: {
        user: {
            get() {
                return this.$store.state.user.user;
            }
        },
        name: {
            get() {
                return this.$store.state.user.name;
            }
        },
        jwtToken: {
            get() {
                return this.$store.state.auth.token;
            }
        }
    },
    props: {}
}
</script>>
