<template>
<div class="modal fade" :id="'modalPedidoDetalle'" tabindex="-1" role="dialog" aria-labelledby="modalDireccion" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content" style="padding: 0px 15px;">
            <div class="modal-header">
                <div class="row h-100 p-0 w-100 text-center">
                    <div class="col-md-12 my-auto title-ps pt-4 title-modal">Pedido N° <b>{{data.id}}</b> - {{getDate(data.fecha_ingreso)}}</div>
                </div>
            </div>
            <div class="modal-body">
                <div class="row">
            <div class="col-md-12" v-if="data.fecha_impreso != null">
                <h6>Fecha Impreso : <b>{{getDate(data.fecha_impreso)}}</b></h6>
            </div>
            <div class="col-md-4">
                <h6 class="text-card">Usuario: <b>{{data.user.email}}</b></h6>
            </div>
            <div class="col-md-4">
                <h6 class="text-card">Nombre: <b>{{data.user.nombre}}</b></h6>
            </div>
            <div class="col-md-4">
                <h6 class="text-card">Celular: <b>{{data.user.celular}}</b></h6>
            </div>
            <div class="col-md-4 pt-1">
                <h6 class="text-card">Estado del pedido: <b :class="this.getClassEstado(data.estado)">{{data.estado}}</b></h6>
            </div>

            <div class="col-md-4 pt-1">
                <h6 class="text-card">Total del pedido: <b class="estado-pago">{{formatearMoneda(data.precio_total)}}</b></h6>
            </div>
            <div class="col-md-12">
                <template v-if="this.getJsonOrder(data) != null">
                    <div v-for="(dataCotizacion,index) in getJsonOrder(data)" :key="index" class="card m-3" style="background-color: #f7f7f7;">
                        <div class="card-title">
                            <div class="col-md-12" style="background-color:#dbdbdb;">
                                <h6 class="text-card p-2" style="font-size:18px;color:rgb(10, 106, 203)">Orden #{{index+1}} ( {{formatearMoneda(parseInt(getPriceOrder(dataCotizacion.files)) + parseInt(dataCotizacion.totalTerminado))}} )</h6>
                            </div>
                        </div>
                        <div class="row p-2">
                            <table class="table table-hover table-striped  table-condensed table-bordered mt-4">
                                <thead>
                                    <th class="text-center color-th">Tamaño</th>
                                    <th class="text-center color-th">Formato</th>
                                    <th class="text-center color-th">Calidad</th>
                                    <th class="text-center color-th">Faz</th>
                                    <th class="text-center color-th">Tipo</th>
                                    <th class="text-center color-th">Terminado</th>
                                    <th class="text-center color-th" v-if="dataCotizacion.terminadoDesc != 'SUELTO'">Anillado</th>
                                    <th class="text-center color-th">Cantidad</th>
                                    <th class="text-center color-th">Terminado</th>
                                    <th class="text-center color-th">Precio</th>
                                </thead>
                                <tbody>
                                    <td data-label="Tamaño" class="text-center color-td" style="border-width:1px 1px 1px 0!important">{{dataCotizacion.tamHojasDesc.split("-")[0]}} </td>
                                    <td data-label="Tamaño" class="text-center color-td-grey" style="border-width:1px 1px 1px 0!important">{{dataCotizacion.formatoDesc}} </td>
                                    <td data-label="Tamaño" class="text-center color-td" style="border-width:1px 1px 1px 0!important"> <b :class="getBackgroundColor(dataCotizacion.calidadDesc)">{{dataCotizacion.calidadDesc}}</b></td>
                                    <td data-label="Tamaño" class="text-center color-td-grey" style="border-width:1px 1px 1px 0!important">{{dataCotizacion.fazDesc.split("-")[0]}} </td>
                                    <td data-label="Tamaño" class="text-center color-td" style="border-width:1px 1px 1px 0!important">{{dataCotizacion.tipoPapelDesc.replace("GRAMOS","GR")}} </td>
                                    <td data-label="Tamaño" class="text-center color-td-grey" style="border-width:1px 1px 1px 0!important">{{dataCotizacion.terminadoDesc}} </td>
                                    <td data-label="Tamaño" class="text-center color-td" style="border-width:1px 1px 1px 0!important" v-if="dataCotizacion.terminadoDesc != 'SUELTO'"> {{dataCotizacion.agrupado == "A" ? "AGRUPADO" : "INDIVIDUAL"}}</td>
                                    <td data-label="Tamaño" class="text-center color-td-grey" style="border-width:1px 1px 1px 0!important">{{dataCotizacion.cantidad}} </td>
                                    <td data-label="Tamaño" class="text-center color-td" style="border-width:1px 1px 1px 0!important"> {{formatearMoneda(dataCotizacion.totalTerminado)}}</td>
                                    <td data-label="Tamaño" class="text-center color-td-grey" style="border-width:1px 1px 1px 0!important"> {{formatearMoneda(getPriceOrder(dataCotizacion.files))}}</td>
                                </tbody>
                            </table>
                            <!-- <div class="col-md-4">
                                <h6 class="text-card">Tamaño: <b>{{dataCotizacion.tamHojasDesc}}</b></h6>
                            </div>
                            <div class="col-md-4">
                                <h6 class="text-card">Formato: <b>{{dataCotizacion.formatoDesc}}</b></h6>
                            </div>
                            <div class="col-md-4">
                                <h6 class="text-card">Calidad: <b :class="getBackgroundColor(dataCotizacion.calidadDesc)">{{dataCotizacion.calidadDesc}}</b></h6>
                            </div>
                            <div class="col-md-4">
                                <h6 class="text-card">Faz: <b>{{dataCotizacion.fazDesc}}</b></h6>
                            </div>
                            <div class="col-md-4">
                                <h6 class="text-card">Tipo: <b>{{dataCotizacion.tipoPapelDesc}}</b></h6>
                            </div>
                            <div class="col-md-4">
                                <h6 class="text-card">Terminado: <b>{{dataCotizacion.terminadoDesc}}</b></h6>
                            </div>
                            <div class="col-md-4" v-if="dataCotizacion.terminadoDesc != 'SUELTO'">
                                <h6 class="text-card">Anillado: <b>{{dataCotizacion.agrupado == "A" ? "AGRUPADO" : "INDIVIDUAL"}}</b></h6>
                            </div>
                            <div class="col-md-4">
                                <h6 class="text-card">Cantidad Juegos: <b>{{dataCotizacion.cantidad}}</b></h6>
                            </div>
                            <div class="col-md-4" v-if="dataCotizacion.totalTerminado > 0">
                                <h6 class="text-card">Terminado: <b class="estado-pago-order">{{formatearMoneda(dataCotizacion.totalTerminado)}}</b></h6>
                            </div>
                            <div class="col-md-4">
                                <h6 class="text-card">Precio: <b class="estado-pago-order">{{formatearMoneda(getPriceOrder(dataCotizacion.files))}}</b></h6>
                            </div> -->
                            <div class="col-md-12">
                                <div class="row align-items-end justify-content-end">
                                    <!-- <div class="col-md-2 ps-0" style="cursor: pointer;">
                                        <a @click="descargarTodo(dataCotizacion.files,data.id,index+1,'btn_'+data.id+'_'+index)" :id="'btn_'+data.id+'_'+index" style="background-color: #0A6ACB; border: none;position: relative;cursor:pointer !important" class="width_button btn btn-primary lift p-2 me-4 mt-3 w-100">Archivos ({{ dataCotizacion.files.length }})&nbsp;&nbsp; <i class="fas fa-download" style="font-size:13px;"></i></a>
                                    </div> -->
                                    <div class="col-md-12">
                                        <table class="table table-hover table-striped  table-condensed table-bordered table-mobile mt-4">
                                            <thead>
                                                <th class="text-center color-th">Nombre</th>
                                                <th class="text-center color-th">Archivo</th>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(file,index) in dataCotizacion.files" :key="index">
                                                    <td data-label="Tamaño" class="text-center color-td"> Archivo N° {{ index+1 }}</td>
                                                    <td data-label="Tamaño" class="text-center color-td"> {{ formatearMoneda(file.price) }}</td>
                                                    <td data-label="Tamaño" class="text-center color-td">
                                                        <a @click="getArchivos(file.nameKey)" :id="'btn_'+data.id+'_'+index" style="background-color: #0A6ACB; border: none;position: relative;cursor:pointer !important" class="width_button btn btn-primary lift p-2 me-4 mt-3 w-25">&nbsp;&nbsp; <i class="fas fa-download" style="font-size:13px;"></i></a>
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <table class="table table-hover table-striped  table-condensed table-bordered table-mobile">
                        <thead>
                            <th class="text-center color-th">Tamaño</th>
                            <th class="text-center color-th">Formato</th>
                            <th class="text-center color-th">Calidad</th>
                            <th class="text-center color-th">Faz</th>
                            <th class="text-center color-th">Tipo</th>
                            <th class="text-center color-th">Terminado</th>
                            <th class="text-center color-th">Cantidad</th>
                            <th class="text-center color-th">Precio</th>
                            <th class="text-center color-th" style="width:6%"></th>
                        </thead>
                        <tbody>
                            <tr v-for="(dataCotizacion,index) in data.cotizacion" :key="index">
                                <td data-label="Tamaño" class="text-center color-td">{{dataCotizacion.nombre_tam_hoja}}</td>
                                <td data-label="Formato" class="text-center color-td">{{dataCotizacion.nombre_formato_hoja}} <a v-if="dataCotizacion.desc_formato_hoja != '-' != null && dataCotizacion.desc_formato_hoja != '-'">- {{dataCotizacion.desc_formato_hoja}}</a></td>
                                <td data-label="Calidad" :class="'text-center color-td ' + getBackgroundColor(dataCotizacion.nombre_calidad_hoja)">{{dataCotizacion.nombre_calidad_hoja}} <a v-if="dataCotizacion.desc_calidad_hoja != '-' != null && dataCotizacion.desc_calidad_hoja != '-'">- {{dataCotizacion.desc_calidad_hoja}}</a> </td>
                                <td data-label="Faz" class="text-center color-td">{{dataCotizacion.nombre_faz_hoja}} </td>
                                <td data-label="Tipo" class="text-center color-td">{{dataCotizacion.nombre_tipo_hoja}} <a v-if="dataCotizacion.desc_tipo_hoja != '-' != null && dataCotizacion.desc_tipo_hoja != '-'">- {{dataCotizacion.desc_tipo_hoja}}</a> </td>
                                <td data-label="Terminado" class="text-center color-td">{{dataCotizacion.nombre_terminado_hoja}} <a v-if="dataCotizacion.desc_terminado_hoja != '-' != null && dataCotizacion.desc_terminado_hoja != '-'">- {{dataCotizacion.desc_terminado_hoja}}</a></td>
                                <td data-label="Cantidad" class="text-center color-td">{{dataCotizacion.cantidad_copias}}</td>
                                <td data-label="Precio" class="text-center color-td">{{formatearMoneda(dataCotizacion.precio)}}</td>
                                <td v-if="dataCotizacion.link_drive == null" data-label="Archivos" class="text-center color-td"><a @click="getArchivos(dataCotizacion.archivo_key)" :id="'btn_'+dataCotizacion.archivo_key" class="width_button btn btn-primary lift p-2 me-4 mt-3" style="background-color: green; border: none;position: relative;bottom: 5px;left: 10px;"><i class="fas fa-download" style="font-size:13px;"></i></a></td>
                                <td v-if="dataCotizacion.link_drive != null" data-label="Link" class="text-center color-td"><a @click="openLink(dataCotizacion.link_drive)" class="width_button btn btn-primary color-btn lift p-2 me-4 mt-3" style="background-color: green; border: none;position: relative;bottom: 5px;left: 10px;"><i class="fas fa-link" style="font-size:13px;"></i></a></td>
                            </tr>
                        </tbody>
                    </table>
                </template>
            </div>
            <div class="col-md-12 col-xs-12 text-end">
                <a class="width_button btn btn-primary color-btn lift  p-2 mt-3 me-3" @click="eliminarPedido(data.id)" style="border:none;background-color:#767676 !important">Eliminar Pedido <i class="ps-2 fas fa-trash-alt" style="font-size:23px;"></i></a>
                <a v-if="data.fecha_impreso != null" @click="volverPedido(data.id)" class="width_button btn btn-primary color-btn lift  p-2 mt-3" style="border:none;">No Realizado <i class="ps-2 fas fa-retweet" style="font-size:23px;"></i></a>
                <a v-if="data.fecha_impreso == null && data.estado == 'PAGO PENDIENTE' && data.fecha_impreso == null" @click="rechazarPedido(data.id)" class="width_button btn btn-primary color-btn lift me-3  p-2 mt-3" style="cursor:pointer;border:none;background-color:#bda14a !important">Rechazar Pedido <i class="ps-2 fas fa-trash-alt me-2" style="font-size:23px;"></i></a>
                <a v-if="data.fecha_impreso == null && data.estado == 'PAGO PENDIENTE' && data.fecha_impreso == null" @click="sendEmailCotizacion(data.id)" class="width_button btn btn-primary color-btn lift me-3  p-2 mt-3" style="cursor:pointer;border:none;">Enviar Cotizaci&oacute;n <i class="ps-2 fas fa-envelope me-2" style="font-size:23px;"></i></a>
                <a v-if="data.fecha_impreso == null && data.estado != 'PAGO PENDIENTE'" @click="updatePedido(data.id,data.precio_total)" class="width_button btn btn-primary color-btn lift  p-2 mt-3" style="border:none;background-color:#35972a!important;cursor:pointer;">Pedido Realizado <i class="ps-2 fas fa-thumbs-up" style="font-size:23px;"></i></a>
            </div>
        </div>
            </div>
            <div class="modal-footer">
                <button class="btn lift lift-sm" style="background-color:grey;color:white" type="button" data-bs-dismiss="modal" @click="closeModal()">Cerrar</button>
            </div>
        </div>
    </div>
</div>
</template>

<style>
.title-modal {
    font-size: 20px;
}

.color-th {
    font-weight: 500;
}

.color-td {
    color: #24a4d1;
    font-weight: 600;
    font-size: 14px;
}

@media (max-width: 600px) {
    .width_button {
        width: 100%;
    }
}

.text-card {
    font-size: 14px;
}

.table>tbody>tr>td {
    vertical-align: middle;
}

.title {
    color: black !important;
    cursor: auto;
}

.color-title-domicilio {
    color: #1f3c7b;
}

.send-data-register {
    background: white !important;
}

.estado-pendiente {
    color: white;
    background: orange;
    padding: 5px;
    font-size: 12px;
    border-radius: 7px;
}

.estado-pago {
    color: white;
    background: #35972a;
    padding: 5px;
    font-size: 12px;
    border-radius: 7px;
}

.estado-rechazado {
    color: white;
    background: #f75454;
    padding: 5px;
    font-size: 12px;
    border-radius: 7px;
}

.descargado {
    background-color: #24a4d1 !important;
}
</style>

<script>
import router from '@/router/index.js' // Vue router instance
import $ from 'jquery'
import Vue from 'vue'
import Spinner from './spinner'
import moment from 'moment'



AWS.config.update({
    accessKeyId: process.env.VUE_APP_API_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.VUE_APP_API_AWS_SECRET_ACCESS_KEY
});
const s3 = new AWS.S3();
const BUCKET_NAME = process.env.VUE_APP_API_AWS_BUCKET;

export default {
    components: {
        Spinner
    },
    mounted: function () {
        this.getPedidoById();
    },
    methods: {
        getPriceOrder(files){
            var price = 0;
            files.forEach(element =>{
                price = parseInt(price) + parseInt(element.price);
            })
            return price;
        },
        getPedidoById: function () {
            this.$store.dispatch("pedidos/getPedidoCompletadoByID", this.idPedido);
        },
        closeModal: function () {
            this.$emit('onClose');
        },
        sendEmailCotizacion(id) {
            Vue.swal({
                title: "Tío Toto",
                html: "Ingrese el valor de la cotizaci&oacute;n: <input class='w-25 form-control' id='precioCotizacion_" + id + "' placeholder='precio' style='display: initial!important;padding:8px!important;' /><br> <textarea rows='4' placeholder='Observaciones' class='form-control mt-2 mb-2' id='observaciones_" + id + "'></textarea> <h6 style='font-size: 14px;color: grey;'>(*)Al enviar el precio de la cotizacoin, se le va a enviar un email al usuario con el link de mercado pago.</h6>",
                showCancelButton: true,
                icon: 'question',
                confirmButtonColor: '#3085d6',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Enviar'
            }).then((result) => {
                if (result.value) {
                    let obj = new Object;
                    obj.id_pedido = id;
                    obj.observaciones = $("#observaciones_" + id).val()
                    obj.precio_cotizacion = $("#precioCotizacion_" + id).val()
                    if (obj.precio_cotizacion != null && obj.precio_cotizacion.length > 0) {
                        this.$store.dispatch("pedidos/sendCotizacionPedido", obj);
                    } else {
                        Vue.swal("Tio Toto", "El precio no puede estar vacio", "info")
                    }
                }
            });
        },
        rechazarPedido(id) {
            Vue.swal({
                title: "Tío Toto",
                html: "Estas seguro/a que queres rechazar el pedido N° " + id + "? <br> <textarea rows='4' placeholder='Observaciones' class='form-control mt-2 mb-2' id='observaciones_" + id + "'></textarea>",
                showCancelButton: true,
                icon: 'question',
                confirmButtonColor: '#3085d6',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Confirmar'
            }).then((result) => {
                if (result.value) {
                    let obj = new Object;
                    obj.id_pedido = id;
                    obj.observaciones = $("#observaciones_" + id).val()
                    this.$store.dispatch("pedidos/rechazarPedido", obj);
                }
            });
        },
        eliminarPedido(id) {
            Vue.swal({
                title: "Tío Toto",
                html: "¿Estas seguro/a que queres eliminar el pedido N° " + id + "?. Recordá devolverle el dinero al cliente en el caso de estar pago",
                showCancelButton: true,
                icon: 'question',
                confirmButtonColor: '#3085d6',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Confirmar'
            }).then((result) => {
                if (result.value) {
                    this.$store.dispatch("pedidos/eliminarPedido", id);
                }
            });
        },
        openLink(link) {
            window.open(link, '_blank').focus();
        },
        getTipoPedido(value) {
            if (value == "0") {
                return "Link Drive"
            } else {
                return "Archivo Cargado"
            }
        },
        formatearMoneda(value) {
            let val = (value / 1).toFixed(0).replace('.', ',')
            return "$" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        getClassEstado(estado) {
            if (estado.trim().toUpperCase() == "PAGO APROBADO" || estado.trim().toUpperCase() == "COMPLETADO") {
                return "estado-pago"
            } else if (estado.trim().toUpperCase() == "PAGO RECHAZADO") {
                return "estado-rechazado"
            } else {
                return "estado-pendiente"
            }
        },
        getDate(date) {
            return moment(date, 'YYYY-MM-DD HH:mm').format("DD/MM/YYYY HH:mm");
        },

        async descargarTodo(archivos, cotizacionID, order, id) {
            this.$store.commit("SET_BLOQUEADO", true);
            try {
                const promises = archivos.map(element => 
                    this.getArchivos(element.nameKey, archivos.length, id, cotizacionID, order)
                );

                await Promise.all(promises);
                setTimeout(() => {
                    this.$store.commit("SET_BLOQUEADO", false);
                }, archivos.length > 15 ? 15000 : 500);

                Vue.swal("Tio Toto", "Se descargaron todos los archivos correctamente", "success");
            } catch (error) {
                this.$store.commit("SET_BLOQUEADO", false);
                Vue.swal("Tio Toto", JSON.stringify(error), "error");
            }
        },

        async getArchivos(dayaKey, cantidad, id, cotizacionID, order) {
            try {
                const response = await this.$store.dispatch("archivos/getArchivosByCotizacion", dayaKey);
                
                for (const element of response) {
                    await this.descargarArchivos(element, cotizacionID, order);
                }
                
                this.changeIconDownload(dayaKey, id);
                cantidad = cantidad == null ? 1 : cantidad;
                this.$store.commit("SET_BLOQUEADO", false);

                Vue.swal("Tio Toto", "Se descargó el archivo(s) correctamente", "success");
            } catch (error) {
                Vue.swal("Tio Toto", JSON.stringify(error.response.data), "error");
                throw error;
            }
        },

        async descargarArchivos(element, cotizacionID, order) {
            try {
                const data = await this.getFileFromS3(element.data_key);
                this.saveFile(data.Body, element.nombre_file, cotizacionID, order);
            } catch (error) {
                Vue.swal("Tio Toto", "Error al intentar descargar el archivo: " + error.message, "error");
                throw error;
            }
        },

        getFileFromS3(dataKey) {
            return new Promise((resolve, reject) => {
                s3.getObject({ Bucket: BUCKET_NAME, Key: dataKey }, (error, data) => {
                    if (error) {
                        reject(error);
                    } else {
                        resolve(data);
                    }
                });
            });
        },

        saveFile(dataBody, fileName, cotizacionID, order) {
            console.log(fileName)
            const blob = new Blob([dataBody]);
            if (cotizacionID != null) {
                fileName = `pedido-${cotizacionID}__orden-${order}__${fileName}`;
            }

            if (navigator.msSaveBlob) {
                navigator.msSaveBlob(blob, fileName);
            } else {
                const link = document.createElement('a');
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', fileName);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url); // Liberar la URL creada
            }
        },

        changeIconDownload(dayaKey, id) {
            let element;
            if (id != null) {
                element = document.getElementById(id);
            } else {
                element = document.getElementById("btn_" + dayaKey);
            }
            element.classList.add("descargado");
        },
        getBackgroundColor(texto) {
            if (texto.toUpperCase().includes("COLOR")) {
                return "texto-background-color";
            }
        },
        updatePedido(idPedido, precio) {
            if (precio != "0") {
                Vue.swal({
                    title: 'Tio Toto',
                    text: "¿Estas segura/o que queres confirmar el pedido " + idPedido + "?",
                    icon: 'info',
                    confirmButtonColor: '#3085d6',
                    showCancelButton: true,
                    cancelButtonText: 'No',
                    confirmButtonText: 'Si'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$store.dispatch("pedidos/updatePedido", idPedido);
                    }
                })
            } else {
                Vue.swal({
                    title: 'Tio Toto',
                    text: "No se puede confirmar un pedido que tenga precio $0",
                    icon: 'info',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Cerrar'
                })
            }
        },
        volverPedido(idPedido) {
            Vue.swal({
                title: 'Tio Toto',
                text: "¿Estas segura/o que queres enviar el pedido " + idPedido + " a pendientes?",
                icon: 'info',
                confirmButtonColor: '#3085d6',
                showCancelButton: true,
                cancelButtonText: 'No',
                confirmButtonText: 'Si'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch("pedidos/volverPedido", idPedido);
                }
            })
        },
        getJsonOrder(data) {
            if (data.pedido_json) {
                let orderJson = JSON.parse(data.pedido_json);
                return orderJson.order;
            }
            return null;
        }
    },
    watch: {
        idPedido() {
            this.getPedidoById();
        }
    },
    computed: {
        data: {
            get() {
                return this.$store.state.pedidos.pedidoCompletado;
            }
        },
    },
    props: {
        idPedido: String,
    }
}
</script>>
