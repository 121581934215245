<template>
<div>
    <NavBar />
    <Spinner />
    <div id="layoutSidenav_content mb-4">
        <main>
            <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-0 pt-5">
                <div class="container-xl px-4">
                    <div class="page-header-content pt-4">
                        <div class="row align-items-center justify-content-between">
                            <div class="col-12 mt-4 text-center">
                                <h1 class="page-header-title text-center" style="display:block;margin-top:60px">
                                    <div class="page-header-icon"></div>
                                    Centro de Administraci&oacute;n
                                </h1>
                                <div class="page-header-subtitle">Tio Toto</div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <section class="section-items pt-3 pb-3" style="background-color:#d9d9d9">
				<div class="container pt-3 pb-2">
					<div class="row justify-content-center">
						<div class="col-md-12 text-center">
							<h4 class="title-como-imprimir"><i class="fas fa-print"></i> - PEDIDOS PENDIENTES ({{totalPedido}})</h4>
						</div>
					</div>
				</div>
			</section>
            <!-- Main page content-->
            <div class="container-xl mb-4">
                <div class="mb-4" style="margin-botom:50px;">
                    <div class="card-body pt-0">
                        <div class="row justify-content-center">
                            <div class="col-xxl-11 col-xl-8">
                                <div class="row gx-3" v-if="pedidosPendientes.length != 0">
                                    <div class="col-md-6">
                                        <input id="inputFilter" placeholder="Filtrar pedidos pendientes" name="search" class="w-100 mt-4 form-control searchbox-input" @keyup="filterPedidos()">
                                    </div>
                                    <div class="col-md-6 mt-4">
                                        <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                                            <input @click="filtrarTodos" type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked>
                                            <label @click="filtrarTodos" class="btn btn-outline-primary" for="btnradio1">Todos los pedidos <i class="fas fa-globe ms-2" style="font-size:25px"></i></label>

                                            <input @click="filtrarCompletados" type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off">
                                            <label @click="filtrarCompletados" class="btn btn-outline-green" for="btnradio2">Pago Aprobado <i class="fas fa-dollar-sign ms-2" style="font-size:25px"></i></label>

                                            <input @click="filtrarPendientes" type="radio" class="btn-check" name="btnradio" id="btnradio3" autocomplete="off">
                                            <label @click="filtrarPendientes" class="btn btn-outline-orange" for="btnradio3">Pago Pendientes <i class="fas fa-exclamation-circle ms-2" style="font-size:25px"></i></label>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="card mt-4">
                                            <div class="card-body body-filter p-3" style="box-shadow: 0 0.15rem 1.75rem 0 rgb(33 40 50 / 15%)">
                                                <h4 class="title-como-imprimir text-end">PEDIDOS SELECCIONADOS IMPRESOS ({{this.pedidosSeleccionados.length}}) 
                                                    <a @click="sendImpresos()"  class="width_button btn btn-primary lift text-end p-2 me-2" style="background-color: #24a4d1; border: none;position: relative;bottom: 1px;left: 10px;">
                                                      Enviar Todos <i class="fas fa-envelope" style="font-size:13px;margin-left:10px;"></i>
                                                    </a>
                                                </h4>
                                                <h4 class="title-como-imprimir text-end">PEDIDOS SELECCIONADOS A RECHAZAR ({{this.pedidosSeleccionados.length}}) 
                                                    <a @click="trashImpresos()"  class="width_button btn btn-primary lift text-end p-2 me-2" style="background-color: #b54646; border: none;position: relative;bottom: 1px;left: 10px;">
                                                      Rechazar Todos <i class="fa fa-trash" style="font-size:13px;margin-left:10px;"></i>
                                                    </a>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12" v-for="(pedido,index) in this.pedidosPendientes" :key="index" v-if="pedido.estado == 'PAGO APROBADO' || pedido.estado=='PAGO PENDIENTE'">
                                        <PedidosPendientes :data="pedido"/>
                                    </div>
                                </div>
                                <div class="row gx-3 text-center mt-4" v-else>
                                    <div class="col-md-12">
                                        No hay pedidos pendientes
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </main>
    </div>
</div>
</template>

<style>
.titulo-login {
    font-size: 30px;
    color: #24a4d1;
}

.color-btn {
    background: #24a4d1 !important;
    border-color: #24a4d1 !important;
}

.btn-link {
    color: #24a4d1;
    cursor: pointer;
}

.btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
    color: #fff;
    background-color: #24a4d1;
    border-color: #24a4d1;
}
.btn-outline-primary {
    color: #24a4d1;
    border-color: #24a4d1;
    height: 48px;
}

.btn-outline-primary:hover {
    color: white;
    background-color: #24a4d1;
    border-color: #24a4d1;
}

.btn-check:checked + .btn-outline-green, .btn-check:active + .btn-outline-green, .btn-outline-green:active, .btn-outline-green.active, .btn-outline-green.dropdown-toggle.show {
    color: #fff;
    background-color: #35972a;
    border-color: #35972a;
}
.btn-outline-green {
    color: #35972a;
    border-color: #35972a;
    height: 48px;
}

.btn-outline-green:hover {
    color: white;
    background-color: #35972a;
    border-color: #35972a;
}


.btn-check:checked + .btn-outline-orange, .btn-check:active + .btn-outline-orange, .btn-outline-orange:active, .btn-outline-orange.active, .btn-outline-orange.dropdown-toggle.show {
    color: #fff;
    background-color: orange;
    border-color: orange;
}
.btn-outline-orange {
    color: orange;
    border-color: orange;
    height: 48px;
}

.btn-outline-orange:hover {
    color: white;
    background-color: orange;
    border-color: orange;
}

</style>

<script>
import NavBar from "../components/navBar"
import router from '@/router/index.js' // Vue router instance
import Vue from 'vue'
import Spinner from '../components/spinner'
import $ from 'jquery'
import PedidosPendientes from '../components/pedidos'

import Footer from '../components/footer'
import jquery from "jquery"
export default {
    name: "Home",
    components: {
        NavBar,
        Footer,
        PedidosPendientes,
        Spinner
    },
    data() {
        return {
                 totalPedido: this.$store.state.pedidos.pedidos.length
        }
    },
    mounted: function () {
        this.$store.commit("SET_BLOQUEADO", false);
        this.$store.commit("pedidos/SET_PEDIDOS_COMPLETADOS", "");
        this.getPedidosPendientes();
    },
    methods: {
        trashImpresos(){
            if(this.pedidosSeleccionados.length>0){
                    Vue.swal({
                        title: 'Tio Toto',
                        text: "¿Estas segura/o que queres eliminar los pedidos" + JSON.stringify(this.pedidosSeleccionados) + "?",
                        icon: 'info',
                        confirmButtonColor: '#3085d6',
                        showCancelButton: true,
                        cancelButtonText: 'No',
                        confirmButtonText: 'Si'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.pedidosSeleccionados.forEach(id =>{
                                this.$store.dispatch("pedidos/eliminarPedido", id);
                            })
                            setTimeout(() => {
                                window.location = "/home";
                            }, 10000);
                        
                        }
                    })      
                }else{
                    Vue.swal({
                        title: 'Tio Toto',
                        text: "No hay pedidos seleccionados",
                        icon: 'info',
                    })
            }
        },
        sendImpresos(){
            if(this.pedidosSeleccionados.length>0){
                Vue.swal({
                    title: 'Tio Toto',
                    text: "¿Estas segura/o que queres confirmar los pedidos" + JSON.stringify(this.pedidosSeleccionados) + "?",
                    icon: 'info',
                    confirmButtonColor: '#3085d6',
                    showCancelButton: true,
                    cancelButtonText: 'No',
                    confirmButtonText: 'Si'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.pedidosSeleccionados.forEach(id =>{
                            debugger;
                            this.$store.dispatch("pedidos/updatePedido", id);
                        })
                        setTimeout(() => {
                            window.location = "/home";
                        }, 10000);
                       
                    }
                })      
            }else{
                Vue.swal({
                    title: 'Tio Toto',
                    text: "No hay pedidos seleccionados",
                    icon: 'info',
                })
            }
           
        },
        filtrarTodos(){
            jquery("#inputFilter").val("")
            this.filterPedidos();
        },
        filtrarCompletados(){
            jquery("#inputFilter").val("PAGO APROBADO")
            this.filterPedidos();
        },
        filtrarPendientes(){
            jquery("#inputFilter").val("PAGO PENDIENTE")
            this.filterPedidos();
        },
        filterPedidos(){
            const input = document.querySelector(".searchbox-input");
            const cards = document.getElementsByClassName("card-filter");
            let filter = input.value
            let totalPedidos = 0;
            for (let i = 0; i < cards.length; i++) {
                let title = cards[i].querySelector(".body-filter");
                if (title.innerText.toUpperCase().replaceAll(" ","").includes(filter.toUpperCase().replaceAll(" ",""))) {
                    totalPedidos++;
                    cards[i].classList.remove("d-none")
                } else {
                    cards[i].classList.add("d-none")
                }
            }
            this.totalPedido = totalPedidos;
        },
        getPedidosPendientes() {
            this.$store.dispatch("pedidos/getPedidosPendientes");
        },
    },
    computed: {
        activo: {
            get() {
                return this.$store.state.user.activo;
            },
            set(value) {
                this.$store.commit("user/SET_ACTIVO", value);
            },
        },
        pedidosPendientes: {
            get() {
                return this.$store.state.pedidos.pedidos;
            }
        },
        pedidosSeleccionados: {
            get() {
                return this.$store.state.pedidos.pedidosSeleccionados;
            }
        }
    },
};
</script>
