import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from '../store/index'; // vuex store instance

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'login',
    component: ()=> import('../view/login.vue')
  }, 
  {
    path: '/login',
    name: 'login',
    component: ()=> import('../view/login.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: ()=> import('../view/home.vue')
  },
  {
    path: '/pedidosCompletados',
    name: 'pedidosCompletados',
    component: ()=> import('../view/pedidosCompletados.vue')
  },
  {
    path: '/listaPrecios',
    name: 'listaPrecios',
    component: ()=> import('../view/listaPrecio.vue')
  },
  {
    path: '/preguntasFrecuentes',
    name: 'preguntasFrecuentes',
    component: ()=> import('../view/preguntasFrecuentes')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  try {
    let state = Store;
    if(to.path == "/login" || to.path =="/"){
      next();
    }else{
      
      if (state.state.auth.token != null && state.state.auth.token != "" && state.state.auth.token.length>10) {
          next();
        } else {
          state.commit('SET_ROUTER',"/");
          next("/login");
      }
    }
  } catch (error) {
    state.commit('SET_ROUTER',"/");
    next("/")
  }
})


export default router
