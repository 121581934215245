import Vue from 'vue'
import Vuex from 'vuex'
import axiosToken from '../../service/axiosToken'
import router from '../../router';
import store from '../index'; // vuex store instance
Vue.use(Vuex)

export default ({
    namespaced: true,
    state: {
        listaPrecio:[],
        listaTerminado:[],

    },
    mutations: {
         SET_LISTA_PRECIO(state, dato) {
            state.listaPrecio = dato;
        },
        SET_LISTA_TERMINADO(state, dato) {
            state.listaTerminado = dato;
        },
    },
    actions: {
        getListaPrecio({ dispatch, commit }) {
            store.commit('SET_BLOQUEADO', true);
            var qs = require('qs');
            var config = {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Access-Control-Allow-Origin': '*'
                },
            };
            axiosToken.get("api/getListaPrecios",config).then(function (response) {
                store.commit('SET_BLOQUEADO', false);
                store.commit("listaPrecio/SET_LISTA_PRECIO",response.data);
            }).catch(function (error) {
                Vue.swal("Tio Toto",error.response.data,"info");
                store.commit('SET_BLOQUEADO', false);
            })
        },
        getListaTerminado({ dispatch, commit }) {
            store.commit('SET_BLOQUEADO', true);
               var qs = require('qs');
               var config = {
                   headers: {
                       'Content-Type': 'application/json;charset=UTF-8',
                       'Access-Control-Allow-Origin': '*'
                   },
               };
               axiosToken.get("api/getListaTerminadoPrecios",config).then(function (response) {
                   store.commit('SET_BLOQUEADO', false);
                   store.commit("listaPrecio/SET_LISTA_TERMINADO",response.data);
               }).catch(function (error) {
                   Vue.swal("Tio Toto",error.response.data,"info");
                   store.commit('SET_BLOQUEADO', false);
               })
       },
        updatePrecio({ dispatch, commit }, dataIn) {
            store.commit('SET_BLOQUEADO', true);
            var qs = require('qs');
            var data = JSON.stringify(dataIn);
            var config = {
                method: 'post',
                url: '/api/updateListaPrecios',
                data: data,
                headers: {
                    'content-type': 'application/json;charset=utf-8',
                }
            };
            axiosToken(config).then(function (response) {
                store.commit('SET_BLOQUEADO', false);
                Vue.swal({
                    title: "Tio Toto",
                    text: "Se actualizo el precio correctamente",
                    icon: "success"
                })
            }).catch(function (error) {
                store.commit('SET_BLOQUEADO', false);
                Vue.swal("Tio Toto", error.response.data, "info");
            })
        },
        updatePrecioTerminado({ dispatch, commit }, dataIn) {
            store.commit('SET_BLOQUEADO', true);
            var qs = require('qs');
            var data = JSON.stringify(dataIn);
            var config = {
                method: 'post',
                url: '/api/updateListaPreciosTerminado',
                data: data,
                headers: {
                    'content-type': 'application/json;charset=utf-8',
                }
            };
            axiosToken(config).then(function (response) {
                store.commit('SET_BLOQUEADO', false);
                Vue.swal({
                    title: "Tio Toto",
                    text: "Se actualizo el precio correctamente",
                    icon: "success"
                })
            }).catch(function (error) {
                store.commit('SET_BLOQUEADO', false);
                Vue.swal("Tio Toto", error.response.data, "info");
            })
        },
    },
    modules: {}
})